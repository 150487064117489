import axios from 'src/utils/axios';

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const tokenExpired = (logout) => {
  // Check every minute (adjust interval as necessary)
  const interval = 60000; // 60 seconds

  const tokenCheckInterval = setInterval(() => {
    const accessToken = sessionStorage.getItem('accessToken');

    if (accessToken && isValidToken(accessToken)) {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;

      // Check if the token is about to expire within the next minute
      if (decoded.exp <= currentTime + 60) {
        // Token will expire soon, so log out
        logout();
        sessionStorage.removeItem('accessToken');
        clearInterval(tokenCheckInterval);
      }
    } else {
      // Invalid token or not available, log out
      logout();
      clearInterval(tokenCheckInterval);
    }
  }, interval);

  return tokenCheckInterval; // Return interval ID for potential clearing
};

export const setSession = (accessToken, logout) => {
  if (accessToken) {
    sessionStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // Check if token is already expired or about to expire
    if (!isValidToken(accessToken)) {
      logout();
      return null;
    }

    // Start the token expiration check and return the interval ID
    return tokenExpired(logout);
  } 
  sessionStorage.removeItem('accessToken');
  delete axios.defaults.headers.common.Authorization;
  return null;
};
