import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
// LABEL
const LabelQuoteListPage = lazy(() => import('src/pages/dashboard/label/quote/list'));
const LabelQuoteCreatePage = lazy(() => import('src/pages/dashboard/label/quote/new'));
const LabelQuoteEditPage = lazy(() => import('src/pages/dashboard/label/quote/edit'));
// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
// COMPANY
const CompanyListPage = lazy(() => import('src/pages/dashboard/company/list'));
const CompanyCreatePage = lazy(() => import('src/pages/dashboard/company/new'));
const CompanyEditPage = lazy(() => import('src/pages/dashboard/company/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
// SETTING
const SettingLabelPage = lazy(() => import('src/pages/dashboard/setting/label'));
// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
  <Suspense fallback={<LoadingScreen />}>
    <Outlet />
  </Suspense>
</DashboardLayout>
)

const roleGuard = (authRoles) => (          
  <RoleBasedGuard authRoles={authRoles} hasContent>
    <Outlet />
  </RoleBasedGuard>
)

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (<AuthGuard>{layoutContent}</AuthGuard>),
    children: [
      { element: <LabelQuoteCreatePage />, index: true },
      {
        path: 'label',
        children: [
          { element: <LabelQuoteCreatePage />, index: true },
          { path: 'quote', 
            children: [
              { element: <LabelQuoteCreatePage />, index: true },
              { path: 'new', element: <LabelQuoteCreatePage /> },
              { path: 'list', element: <LabelQuoteListPage /> },
              { path: ':id/edit', element: <LabelQuoteEditPage /> },
            ],
          },
        ],
      },
      {
        path: 'user',
        element: (roleGuard(['admin'])),
        children: [
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
        ],
      },
      {
        path: 'user',
        children: [
          { element: <UserAccountPage />, index: true },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'company',
        element: (roleGuard(['admin'])),
        children: [
          { path: 'list', element: <CompanyListPage /> },
          { path: 'new', element: <CompanyCreatePage /> },
          { path: ':id/edit', element: <CompanyEditPage /> },
        ],
      },
      {
        path: 'settings',
        element: (roleGuard(['admin'])),
        children: [
          { path: 'label', element: <SettingLabelPage /> },
        ],
      },
      {
        path: 'order',
        element: (roleGuard(['admin'])),
        children: [
          { element: <OrderListPage /> , index: true },
          { path: 'list', element: <OrderListPage /> },
        ],
      },
    ],
  },
];
