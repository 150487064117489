const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`,
      resendEmailConfirmation: `${ROOTS.AUTH}/jwt/resend-email-confirmation`,
      resetPassword: `${ROOTS.AUTH}/jwt/reset-password`,
      verifyEmail: `${ROOTS.AUTH}/jwt/verify-email`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      edit: (id) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      list: `${ROOTS.DASHBOARD}/order/list`,
    },
    label: {
      root: `${ROOTS.DASHBOARD}/label`,
      quote: {
        root: `${ROOTS.DASHBOARD}/label/quote`,
        new: `${ROOTS.DASHBOARD}/label/quote/new`,
        list: `${ROOTS.DASHBOARD}/label/quote/list`,
        edit: (id) => `${ROOTS.DASHBOARD}/label/quote/${id}/edit`,      
      }
    },
    company: {
      root: `${ROOTS.DASHBOARD}/company/list`,
      new: `${ROOTS.DASHBOARD}/company/new`,
      list: `${ROOTS.DASHBOARD}/company/list`,
      edit: (id) => `${ROOTS.DASHBOARD}/company/${id}/edit`,
    },
    settings: {
      label: `${ROOTS.DASHBOARD}/settings/label`,
    },
  },
};
