import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { GuestGuard } from 'src/auth/guard';
import AuthModernLayout from 'src/layouts/auth/modern';

import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const JwtForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));
const JwtResendEmailConfirmationPage = lazy(() => import('src/pages/auth/jwt/resend-email-confirmation'));
const JwtResetPasswordPage = lazy(() => import('src/pages/auth/jwt/reset-password'));
const JwtVerifyEmailPage = lazy(() => import('src/pages/auth/jwt/verify-email'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: (
    <Suspense fallback={<SplashScreen />}>
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <AuthModernLayout>
            <JwtLoginPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'register',
      element: (
        <GuestGuard>
          <AuthModernLayout title="Manage the job more effectively">
            <JwtRegisterPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'forgot-password',
      element: (
        <GuestGuard>
          <AuthModernLayout title="Forgot Password">
            <JwtForgotPasswordPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'resend-email-confirmation',
      element: (
        <GuestGuard>
          <AuthModernLayout title="Resend Email Confirmation">
            <JwtResendEmailConfirmationPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password',
      element: (
        <GuestGuard>
          <AuthModernLayout title="Reset Password">
            <JwtResetPasswordPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
    {
      path: 'verify-email',
      element: (
        <GuestGuard>
          <AuthModernLayout title="Verify Email">
            <JwtVerifyEmailPage />
          </AuthModernLayout>
        </GuestGuard>
      ),
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [authJwt],
  },
];
