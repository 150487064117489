import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.VITE_HOST_API;
export const HOST_API_AZURE = import.meta.env.VITE_HOST_API_AZURE;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;
// export const FRONTEND_URL = import.meta.env.VITE_FRONTEND_URL;


// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
