import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  company: icon('ic_job'),
  setting: icon('ic_setting'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'overview',
        items: [
          // LABEL
          {
            title: 'label',
            path: paths.dashboard.label.root,
            icon: ICONS.label,
            children: [
                { title: 'new quote', path: paths.dashboard.label.quote.new },
                { title: 'list', path: paths.dashboard.label.quote.list },
              ],
          },
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'management',
        items: [
          // USER
          {
            title: 'user',
            path: paths.dashboard.user.root,
            icon: ICONS.user,
            children: [
              { title: 'account', path: paths.dashboard.user.account },
              { title: 'list', roles: ['admin'], path: paths.dashboard.user.list },
              { title: 'create', roles: ['admin'], path: paths.dashboard.user.new },
            ],
          },
          // ORDER
          // {
          //   title: 'order',
          //   roles: ['admin'],
          //   path: paths.dashboard.order.root,
          //   icon: ICONS.order,
          //   children: [
          //     { title: 'list', path: paths.dashboard.order.list },
          //   ],
          // },
          // COMPANY
          {
            title: 'company',
            roles: ['admin'],
            path: paths.dashboard.company.root,
            icon: ICONS.company,
            children: [
              { title: 'list', path: paths.dashboard.company.list },
              { title: 'create', path: paths.dashboard.company.new },
            ],
          },
          // SETTING
          {
            title: 'settings',
            roles: ['admin'],
            path: paths.dashboard.settings.label,
            icon: ICONS.setting,
            children: [
              { title: 'label', path: paths.dashboard.settings.label },
            ],
          },
        ],
      },
    ],
    []
  );

  return data;
}
